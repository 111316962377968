<template>
  <CRow class="mt-2">
     <CCol sm="5" class="form-inline">
      <label class="mr-2">
        {{$t('label.vesselType')}}:
      </label>
      <v-select 
        :options="tpBuqueOptions"
        label="TpVesselName"
        v-model="tpBuque"
        :placeholder="$t('label.select')"
        class="w-75"
      />
    </CCol>
    <CCol sm="5" class="form-inline">
      <label class="mr-2">
        {{$t('label.stowageRole')}}:
      </label>
      <v-select 
        :options="rolesOptions"
        label="StowageRoleName"
        v-model="rol"
        :placeholder="$t('label.select')"
        class="w-75"
      />
    </CCol>
    <CCol sm="2" class="d-flex justify-content-end align-items-center">
      <div class="d-flex align-items-start">
        <CButton
          shape="square"
          color="add"
          class="d-flex align-items-center"
          v-c-tooltip="{
            content: $t('label.role'),
            placement: 'top-end'
          }"
          @click="toggleAdd"
        >
          <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
        </CButton>
      </div>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
     class="align-center-row-datatable"
        hover
        sorter
        column-filter
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :items="formatedItems"
        :fields="fields"
        :loading="isLoading"
        :noItemsView="tableText.noItemsViewText"
        :items-per-page="5"
        :active-page="activePage"
        pagination
         
      >
        <template #loading>
          <loading/>
        </template>
        <template #RowNumber="{ item }">
          <td class="center-cell">
            {{ item.RowNumber }}
          </td>
        </template>

        <template #Status="{item}">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>

        <template #Details="{item}">
          <td class="center-cell">
              <CButton
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.edit')+$t('label.role'),
                  placement: 'top-end'
                }"
                @click="toggleEdit(item)"
              >
                <CIcon name="pencil" />
              </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>

    <ModalCreateRoleStowage :modal.sync="modalAdd" @submited="handleSubmit" />
    <ModalEditRoleStowage 
      :modal.sync="modalEditRole"
      :role-estiba-data="role"
      @submited="handleSubmit" />
  </CRow>
</template>

<script>
import { DateFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import ModalCreateRoleStowage from './modal-create-role-stowage';
import ModalEditRoleStowage from './modal-edit-role-stowage';

function data() {
  return {
    items: [],
    role: {},
    activePage: 1,
    isLoading: false,
    modalAdd: false,
    modalEdit: false,
    modalEditRole: false,
    tpBuques: [],
    tpBuque: null,
    roles: [],
    rol: null
  };
}

//Created
function created() {
  this.getRolesEstiba();
  this.getTpBuques();
  this.getRoles();
}

//Methods:
function getRolesEstiba(rol, tpBuque) {
  this.isLoading = true;

  let StowageRoleId = rol ? rol.StowageRoleId:'';
  let TpVesselId = tpBuque ? tpBuque.TpVesselId:'';

  this.$http.get("StowageRoleVessel-list", {
      StowageRoleId: StowageRoleId,
      TpVesselId: TpVesselId,
      filter: 'ALL'
  }).then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function getRoles() {
  // this.isLoading = true;

  this.$http.get("StowageRole-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.roles = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    // this.isLoading = false;
  });
}
function getTpBuques() {
  // this.isLoading = true;

  this.$http.get("TpVessel-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.tpBuques = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    // this.isLoading = false;
  });
}

function toggleAdd() {
  this.modalAdd = true;
}
function toggleEdit(item) {
  this.role = Object.assign({}, item);
  this.modalEditRole = true;
}

function handleSubmit(){
  this.getTpBuques();
  this.getRoles();
  this.getRolesEstiba();
}

//Computed:
function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}
function tpBuqueOptions() {
  return this.tpBuques.map((item) => Object.assign({}, item, {
    value: item.TpVesselId,
    label: item.TpVesselName
  }));
}
function rolesOptions() {
  return this.roles.map((item) => Object.assign({}, item, {
    value: item.StowageRoleId,
    label: item.StowageRoleName
  }));
}
function fields() { 
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      filter: false,  _style: 'width:1%; text-align:center;'
    },
    { key: 'TpVesselName', label:this.$t('label.vesselType'), _style:'width:33%;', _classes:"text-uppercase"},
    { key: 'StowageRoleName', label: this.$t('label.stowageRole'), _style:'width:20%;', _classes:"text-uppercase"},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:15%;', _classes:"text-uppercase"},
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:15%;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'), _style:'width:10%;', _classes:"text-center"},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
      _classes:"text-center"
    }
  ];
}
function tableText(){
  return tableTextTranslatedHelpers(this);
}

export default {
  name: 'stowage-roles',
  mixins: [GeneralMixin],
  components: {
    ModalCreateRoleStowage,
    ModalEditRoleStowage,
  },
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data,
  created,
  methods: {
    getRolesEstiba,
    toggleAdd,
    toggleEdit,
    handleSubmit,
    getTpBuques,
    getRoles
  },
  computed: {
    formatedItems,
    tpBuqueOptions,
    rolesOptions,
    fields,
    tableText
  },
  watch: {
    rol: function(newRol) {
      this.getRolesEstiba(newRol, this.tpBuque);
    },
    tpBuque: function(newTpBuque) {
      this.getRolesEstiba(this.rol, newTpBuque);
    },
    active: function(newVal) {
      if(newVal){
        this.getRolesEstiba();
        this.getTpBuques();
        this.getRoles();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.center-cell {
  text-align: center;
}
 
</style>