<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{ $t('label.listStowage') }}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12">
              <CustomTabs @update:activeTab="handleTab">
                <CustomTab :title="$t('label.turns')" iconSrc="/img/iconos/Tab-Turnos-trabajo.svg">
                  <turnos :active="activeTab" class="ml-1 mr-1" />
                </CustomTab>
                <CustomTab :title="$t('label.stowageRoles')" iconSrc="/img/iconos/Tab-Roles.svg">
                  <roles :active="activeTab" class="ml-1 mr-1" />
                </CustomTab>
                <CustomTab :title="$t('label.rolesbyVesselType')" iconSrc="/img/iconos/Tab-Roles.svg">
                  <roles-estiba :active="isActiveTab(2)" class="ml-1 mr-1" />
                </CustomTab>
                <CustomTab :title="$t('label.movements')"  iconSrc="/img/iconos/Tab-Movimientos.svg">
                  <movimientos-estiba :active="activeTab" class="ml-1 mr-1" />
                </CustomTab>
                <CustomTab :title="$t('label.supplies')" iconSrc="/img/iconos/Tab-Insumos.svg">
                  <insumos-estiba :active="isActiveTab(4)" class="ml-1 mr-1" />
                </CustomTab>
                <CustomTab :title="$t('label.location')" iconSrc="/img/iconos/Tab-Ubicacion.svg">
                  <ubicacion-estiba :active="isActiveTab(5)" class="ml-1 mr-1" />
                </CustomTab>  
              </CustomTabs>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>         
</div>
</template>

<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';

import Turnos from './turnos/index';
import Roles from './roles/index';
import RolesEstiba from './stowageRoles/index';
import MovimientosEstiba from './movimientos/index';
import InsumosEstiba from './insumos/index';
import UbicacionEstiba from './ubicacion/index';

//Data
function data() {
  return {
    isSubmit: false,
    isLoadingGeneral: false,
    activeTab: 0
  }
}

//Methods
function handleTab(tab) {
  this.activeTab = tab;
}
function isActiveTab(current) {
  return this.activeTab == current;
}

export default {
  name: 'estiba',
  components: {
    CustomTabs,
    CustomTab,
    Turnos,
    RolesEstiba,
    Roles,
    MovimientosEstiba,
    InsumosEstiba,
    UbicacionEstiba
  },
  data,
  methods: {
   handleTab,
   isActiveTab
  },
  computed: {}
}
</script>
<style scoped>
.preview {
  font-size: 0em;
}
label.text-adjust {
  font-size: 0.8em;
}
.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}
.vs--searchable .vs__dropdown-toggle {
  height: 100%;
}

</style>