<template>
<CModalExtended
  :title="$t('label.nuevo')+' '+$t('label.stowageMovement')"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
  class="modal-content-mov"
>
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12">
        <CCard class="card-simple">
          <CCardBody>
            <CRow>
              <CCol sm="12"> 
                <CInput
                  v-model.trim="$v.movimiento.MovStowageNameEs.$model"
                  v-uppercase
                  :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
                  :invalid-feedback="errorMessage($v.movimiento.MovStowageNameEs)"
                  :is-valid="hasError($v.movimiento.MovStowageNameEs)"
                  :label="$t('label.name')+' '+('(ES)')"
                  :placeholder="$t('label.movementName')+' '+('(ES)')"
                  addLabelClasses="required text-right"
                  maxlength="100"
                >
                </CInput>
              </CCol>
              <CCol sm="12">
                <CInput
                  v-model.trim="$v.movimiento.MovStowageName.$model"
                  v-uppercase
                  :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
                  :invalid-feedback="errorMessage($v.movimiento.MovStowageName)"
                  :is-valid="hasError($v.movimiento.MovStowageName)"
                  :label="$t('label.name')+' '+('(EN)')"
                  :placeholder="$t('label.movementName')+' '+('(EN)')"
                  addLabelClasses="required text-right"
                  maxlength="100"
                >
                </CInput>
              </CCol>
               <!-- <template #append>
                    <div class="d-flex align-items-start">
                      <CButton
                        color="wipe"
                        class="d-flex align-items-center ml-2"
                        @click.stop="clearInputs"
                        v-c-tooltip="{
                          content: $t('label.clearFields'),
                          placement: 'top-end'
                        }"
                      >
                        <CIcon name="cil-brush-alt"/>
                      </CButton>
                    </div>
                  </template> -->
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
  <template #footer>
    <CButton
      color="add"
      class="d-flex align-items-center"
      :disabled="isSubmit"
      @click.stop="submit"
    >
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>

    <!--
      <div v-if="!isSubmit">
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      </div>
      <div v-if="isSubmit">
        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ml-1">{{$t('button.accept')}}</span>
      </div>-->
    </CButton>
    <CButton
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import MovFormValidations from '@/_validations/estiba/MovFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import { MovimientoMixin } from '@/_mixins/estiba';
import ModalMixin from '@/_mixins/modal';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    movimiento: {
      MovStowageNameEs: '',
      MovStowageName: ''
    }
  }
}

//Methods
function submit() {
  try {
  this.isSubmit = true;
  this.$v.movimiento.$touch();
    if (this.$v.movimiento.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let MovStowageJson = {
      MovStowageNameEs: this.movimiento.MovStowageNameEs,
      MovStowageName: this.movimiento.MovStowageName
    };

    this.$http.post("MovStowage-insert", MovStowageJson, { root: 'MovStowageJson' })
    .then(response => {
      if (response && response.status === 200) {
        const messageSuccess = response.data.data[0].Response;
        this.resetForm();
        this.$emit('submited');
        this.modalActive = false;
        this.notifySuccess({text: messageSuccess})
        this.isSubmit=false;
      }
    }).catch(err => {
      this.notifyError({text: err});
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();

  this.modalActive = newVal;
}
function resetForm() {
  this.movimiento.MovStowageNameEs = '';
  this.movimiento.MovStowageName = '';
  this.$v.$reset();
}

//Computeds:

export default {
  name: 'add-movimientos-modal',
  mixins: [
    MovimientoMixin,
    ModalMixin
  ],
  props: {
    modal: Boolean
  },
  data,
  validations: MovFormValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm
  },
  computed: {
    
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    }
  }
}
</script>
<style lang="scss">
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
.modal-content-mov {
  .modal-content {
    width: 70% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>