var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"mt-3"},[_c('loading-overlay',{attrs:{"active":_vm.isLoading,"is-full-page":true,"loader":"bars"}}),_c('CCol',{staticClass:"form-inline",attrs:{"sm":"5"}},[_c('label',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t('label.movement'))+": ")]),_c('v-select',{staticClass:"w-75",attrs:{"options":_vm.movimientosOptions,"placeholder":_vm.$t('label.select')},model:{value:(_vm.movimiento),callback:function ($$v) {_vm.movimiento=$$v},expression:"movimiento"}})],1),_c('CCol',{staticClass:"d-flex justify-content-end align-items-center",attrs:{"sm":"7"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
        content: `${_vm.$t('label.transactionMov')}`, 
        placement: 'top-end'
      }),expression:"{\n        content: `${$t('label.transactionMov')}`, \n        placement: 'top-end'\n      }"}],staticClass:"d-flex align-items-center",attrs:{"color":"add"},on:{"click":_vm.toggleAdd}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","column-filter":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"active-page":_vm.activePage,"pagination":""},scopedSlots:_vm._u([{key:"RowNumber",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_vm._v(" "+_vm._s(item.RowNumber)+" ")])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Details",fn:function({item}){return [_c('td',{staticClass:"py-2 center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: `${_vm.$t('label.edit')} ${_vm.$t('label.transactionMov')}`,
                placement: 'top-end'
              }),expression:"{\n                content: `${$t('label.edit')} ${$t('label.transactionMov')}`,\n                placement: 'top-end'\n              }"}],attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1),_c('add-transac-modal',{attrs:{"modal":_vm.modalAdd},on:{"update:modal":function($event){_vm.modalAdd=$event},"submited":_vm.handleSubmit}}),_c('edit-transac-modal',{attrs:{"modal":_vm.modalEdit,"transac-data":_vm.transac},on:{"update:modal":function($event){_vm.modalEdit=$event},"submited":_vm.handleSubmit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }