<template>
  <CRow class="mt-2">
    <CCol sm="12">
      <CTabs :active-tab="activeTab" @update:activeTab="handleTab" >
        <CTab :title="$t('label.stowageMov')">
          <movimientos-index :active="isActiveTab(0)" :tap="active" class="ml-1 mr-1"/>
        </CTab>
        <CTab :title="$t('label.transactionMovs')">
          <transacciones-index :active="isActiveTab(1)" class="ml-1 mr-1"/>
        </CTab>
        <CTab :title="$t('label.CancellationReasons')">
          <motivo-cancelacion-index :active="isActiveTab(2)" class="ml-1 mr-1"/>
        </CTab>
      </CTabs>
    </CCol>
  </CRow>
</template>

<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import MovimientosIndex from './movimientos-index';
import TransaccionesIndex from './transacciones-index';
import MotivoCancelacionIndex from './motivo-cancelacion-index';

//Funcion data() del ciclo.
function data() {
  return {
    isSubmit: false,
    isLoadingGeneral: false,
    activeTab: 0
  }
}

//Methods:
function handleTab(tab) {
  this.activeTab = tab;
}
function isActiveTab(current) {
  return this.activeTab == current;
}

export default {
  name: 'movimientos',
  components: {
    CustomTabs,
    CustomTab,
    MovimientosIndex,
    TransaccionesIndex,
    MotivoCancelacionIndex
  },
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  data,
  methods: {
    handleTab,
    isActiveTab
  },
  computed: {},
  watch: {
    active: function(newVal) {
      if(newVal == 3)
        this.activeTab = 0;
    }
  }
}
</script>

<style scoped>
</style>